import React from "react";
import { Image as PwsImage } from "pws-design-system/design-system";
import Img from "gatsby-image/withIEPolyfill";
import UsePreviewDataContainer from "../../hooks/stores/usePreviewDataStore";

export default function PrismicImage(props: any): React.ReactElement {
  const [previewData] = UsePreviewDataContainer.useContainer();
  if (previewData !== null) {
    return <PwsImage {...props} />;
  }
  return <Img {...props} />;
}
